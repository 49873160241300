import { writable, readable } from 'svelte/store';

export const project = writable()
export const authPage = writable('login')
export const mobileNavHeight = writable(null)

export const projectToplists = writable([])
export const orgOperators = writable([])
export const orgCasinoGames = writable([])
export const orgGameProviders = writable([])

export const commercialToplists = writable([])
export const lotteryResults = writable([])

export const postData = writable({})
export const postUsers = writable({})
export const postCategories = writable({})

export const previewMode = writable(false)
export const previewSize = writable('small')
export const previewFullHeightMode = writable(false)
export const previewActiveModal = writable(false)

export const postOperators = writable([])
// testing persistent stores
// export const postOperators = writable(
//   browser && (localStorage.getItem("postOperators") || "lol")
// )
// postOperators.subscribe((val) => browser && (localStorage.postOperators = val))

export const translations = readable({
  "Sort": {
    "sv": "Sortera",
  },
  "Load more casinos": {
    "sv": "Ladda fler casinon",
    "no": "Vis flere casinoer"
  },
  "View less casinos": {
    "sv": "Se färre casinon"
  },
  "Visit casino": {
    "sv": "Till casinot",
    "no": "Til casinoet"
  },
  "Visit": {
    "sv": "Besök"
  },
  "Proceed to": {
    "sv": "Vidare till",
    "no": "Videre til"
  },
  "Hot!": {
    "sv": "Hett nu!"
  },
  "New!": {
    "sv": "Nytt!"
  },
  "Popular!": {
    "sv": "Populär!"
  },
  "Crispy!": {
    "sv": "Krispigt!"
  },
  "Remember that...": {
    "sv": "Tänk på att..."
  },
  "Did you know?": {
    "sv": "Visste du att?"
  },
  "Source": {
    "sv": "Källa"
  },
  "Read news": {
    "sv": "Läs nyheten"
  },
  "Read more": {
    "sv": "Läs mer"
  },
  "Articles": {
    "sv": "Artiklar"
  },
  "Found no posts": {
    "sv": "Fant inga artiklar"
  },
  "Last updated": {
    "sv": "Senast uppdaterad"
  },
  "Table of contents": {
    "sv": "Innehåll"
  },
  "Content editor": {
    "sv": "Innehållsansvarig"
  },
  "About": {
    "sv": "Om"
  },
  "Contact us": {
    "sv": "Kontakt oss"
  },
  "Contact": {
    "sv": "Kontakt"
  },
  "Page does not exist": {
    "sv": "Sidan finns ej"
  },
  "Oops.. something went wrong": {
    "sv": "Oops.. nu blev något fel"
  },
  "Search": {
    "sv": "Sök"
  },
  "Thank you for visiting": {
    "sv": "Tack för att du besökte"
  },
  "Click here": {
    "sv": "Klicka här"
  },
  "if you aren't forwarded to": {
    "sv": "om du inte skickas vidare till"
  },
  "Casino has english license": {
    "se": "Casinot har svensk licens",
    "sv": "Casinot har svensk licens",
    "ca": "Casino has canadian license"
  },
  "Casino open for english players": {
    "se": "Casinot öppen för svenska spelare",
    "sv": "Casinot öppen för svenska spelare",
    "ca": "Casino open for canadian players",
    "in": "Casino open for indian players"
  },
  "Our rating": {
    "sv": "Vårt betyg"
  },
  "English license": {
    "se": "Svensk licens",
    "sv": "Svensk licens",
    "ca": "Canadian license"
  },
  "Games offered": {
    "sv": "Spelutbud"
  },
  "Number of games": {
    "sv": "Antal spel"
  },
  "Support": {
    "sv": "Support"
  },
  "Tax free winnings": {
    "sv": "Skattefria vinster"
  },
  "License": {
    "sv": "Licens"
  },
  "Game providers": {
    "sv": "Spelleverantörer"
  },
  "Mobile casino": {
    "sv": "Mobilcasino"
  },
  "Live casino": {
    "sv": "Livecasino"
  },
  "Fast registration": {
    "sv": "Snabbregistrering"
  },
  "E-legitimation": {
    "sv": "E-legitimation"
  },
  "Deposit options": {
    "sv": "Insättninger via"
  },
  "Minimum deposit": {
    "sv": "Minsta insättning"
  },
  "FS no deposit": {
    "sv": "FS utan insättning"
  },
  "Email": {
    "sv": "E-mail"
  },
  "Send email": {
    "sv": "Maila"
  },
  "Phone": {
    "sv": "Telefon"
  },
  "Live chat": {
    "sv": "Livechatt"
  },
  "Telephone": {
    "sv": "Telefon"
  },
  "Website": {
    "sv": "Hemsida"
  },
  "To": {
    "sv": "Till",
    "no": "Til",
    "en": "To"
  },
  "New customers. 18+. Gamble responsibly.": {
    "no": "Kun tilgjengelig for nye kunder. 18+. Spill ansvarlig."
  },
  "Affiliate disclosure": {
    "sv": "Annonsinformation",
    "no": "Annonseinformasjon"
  },
  "No turnover": {
    "sv": "Utan omsättningskrav"
  },
  "Turnover": {
    "sv": "Omsättningskrav"
  },
  "Wagering req.": {
    "sv": "Oms.krav"
  },
  "No bonus": {
    "sv": "Inga bonus"
  },
  "Without": {
    "sv": "Utan"
  },
  "No": {
    "sv": "Inga",
    "no": "Ingen"
  },
  "No2": {
    "en": "No",
    "sv": "Nej"
  },
  "Yes": {
    "sv": "Ja"
  },
  "Founded": {
    "sv": "Lanseringsår"
  },
  "Casino bonus": {
    "sv": "Casinobonus"
  },
  "Sports bonus": {
    "sv": "Sportbonus"
  },
  "Min. odds": {
    "sv": "Minsta odds"
  },
  "News": {
    "sv": "Nyheter"
  },
  "Archive": {
    "sv": "Arkiv"
  },
  "January": {
    "sv": "Januari"
  },
  "February": {
    "sv": "Februari"
  },
  "March": {
    "sv": "Mars"
  },
  "April": {
    "sv": "April"
  },
  "May": {
    "sv": "Maj"
  },
  "June": {
    "sv": "Juni"
  },
  "July": {
    "sv": "Juli"
  },
  "August": {
    "sv": "Augusti"
  },
  "September": {
    "sv": "September"
  },
  "October": {
    "sv": "Oktober"
  },
  "November": {
    "sv": "November"
  },
  "December": {
    "sv": "December"
  },
  "Result": {
    "sv": "Resultat"
  },
  "Facts about": {
    "sv": "Fakta om"
  },
  "More info": {
    "sv": "Mer info"
  },
  "Facts": {
    "sv": "Fakta"
  },
  "Bonus game": {
    "sv": "Bonusspel"
  },
  "Progressive": {
    "sv": "Progressiv"
  },
  "allgames": {
    "sv": "allaspel"
  },
  "Up to": {
    "sv": "Upp till"
  },
  "Oddsbonus": {
    "sv": "Oddsbonus"
  },
  "Next draw": {
    "sv": "Nästa dragning"
  },
  "Sitemap for": {
    "sv": "Webbplatskarta över"
  },
  "Livecasino & Table Games": {
    "sv": "Livecasino/Bordsspel"
  },
  "Odds & Betting": {
    "sv": "Odds/Betting"
  },
  "Bingo": {
    "sv": "Bingo"
  },
  "Lotto": {
    "sv": "Lotto"
  }
})
